<template lang="pug">
.employee-view
  button.back-btn(
    @click="$router.go(-1)")
    i.fa.fa-arrow-left
  template(v-if="isProfileInfoLoaded")
    base-tree-view(
      v-if="permissions.canCurrentEmployeeViewProfile"
      useCursorNodeModal
      :employeeId="userId"
      :loaded="treeIsLoaded"
      :tree="selectedTree"
      :isCursorNodeModalEditable="permissions.canCurrentEmployeeEditProfile")
    .employee-view__no-permissions(v-else)
      h1 {{ 'permissions.no_permissions_to_view' | translate }}
    .panels-layout
      toolBar.center-under-header(v-if="treeIsLoaded && inited")
      drawer-panel(
        v-if="permissions.canCurrentEmployeeViewGoals"
        side="left"
        :width="400"
        add-top-offset
        :minimize="!isGoalsDrawerOpened"
        :title="'pages.my.goals' | translate"
        @on-toggle="isGoalsDrawerOpened = !isGoalsDrawerOpened")
        goals
      drawer-panel(
        side="right"
        :width="450"
        :title="'pages.my.my_profile' | translate"
        :minimize="!isEmployeeDetailDrawerOpened"
        @on-toggle="isEmployeeDetailDrawerOpened = !isEmployeeDetailDrawerOpened")
        recommended-skills-modal(:employee="employeeProfile")
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import BaseTreeView from '../tree-view/BaseTreeView'

import toolBar from '@/components/common/toolbar/index.vue'
import DrawerPanel from '@/components/ui/DrawerPanel'
import Goals from '@/views/my/modals/goals/Index'
import ProfileInfo from '@/components/modals/employee/ProfileInfo'
import RecommendedSkillsModal from '@/views/my/modals/RecommendedSkills/RecommendedSkillsModal'

export default {
  name: 'Index',

  components: {
    BaseTreeView,
    toolBar,
    DrawerPanel,
    Goals,
    ProfileInfo,
    RecommendedSkillsModal
  },

  async mounted () {
    this.init()
    await this.loadDefaultTreeType()
    this.inited = true
    this.checkUserNotCurrentUser()
  },

  data: () => ({
    isProfileInfoLoaded: false,
    employeeProfile: null,
    permissions: {
      canCurrentEmployeeEditProfile: false,
      canCurrentEmployeeViewGoals: false,
      canCurrentEmployeeViewProfile: false
    },
    isGoalsDrawerOpened: false,
    isEmployeeDetailDrawerOpened: false,
    inited: false
  }),

  computed: {
    ...mapState('trees', [
      'globalTree',
      'treeIsLoaded'
    ]),

    ...mapGetters('app', [
      'userInfo'
    ]),

    profileName () {
      return this.$t('pages.profile.profile', {
        name: this.employeeProfile.firstName + "'s"
      })
    },

    userId () {
      return parseInt(this.$route.params.employee_id)
    },

    ...mapGetters('trees', [
      'selectedTree',
      'isLoadLazyTree'
    ])
  },

  methods: {
    ...mapActions('trees', [
      'loadTreeById',
      'loadDefaultTreeType'
    ]),

    ...mapActions('employee', [
      'loadForeighEmployee'
    ]),

    async init () {
      this.isProfileInfoLoaded = false
      const userId = this.$route.params.employee_id
      const { data } = await this.loadForeighEmployee(userId)
      this.employeeProfile = data
      for (const key in data.helpers.permissions) {
        this.permissions[key] = data.helpers.permissions[key]
      }
      if (this.permissions.canCurrentEmployeeViewProfile) {
        await this.loadTreeById({ id: this.userId, lazy: true })
        if (this.isLoadLazyTree) {
          this.loadTreeById({ id: this.userId, lazy: false })
        }
      }
      this.isProfileInfoLoaded = true
    },

    checkUserNotCurrentUser () {
      if (this.userInfo?.id === this.userId) {
        const { params } = this.$route
        if (params?.employee_id) {
          if (params?.badge_id) {
            this.$router.push(`/my/badge/${params.badge_id}`)
          } else if (params?.goal_id) {
            this.$router.push(`/my/goal/${params.goal_id}`)
          } else {
            this.$router.push(`/my`)
          }
        }
      }
    }
  },

  watch: {
    userId () {
      this.init()
      this.checkUserNotCurrentUser()
    }
  }
}
</script>

<style lang="scss">

  .employee-view {
    position: relative;
    height: calc(100vh - 80px);

    &__no-permissions {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .back-btn {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 25px;
    left: 25px;
    width: 40px;
    height: 40px;
    color: $color-inactive-text;
    background-color: $color-white;
    border: 1px solid $inactive-btn;
    border-radius: 4px;
    font-size: 16px;

    &:active {
      background: $inactive-btn;
    }
  }
</style>
